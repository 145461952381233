@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOkCnqEu92Fr1MmgWxP.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmSU5vAw.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Me5Q.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlvAw.ttf) format('truetype');
}
h3 {
  font-weight: initial;
}
body {
  margin: 0;
  /* 
  height: 100vh; */
}
*:not(.draggable-img) {
  font-family: 'Roboto', sans-serif;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}
.page-game-detail {
  height: 100vh;
}
.page-game-detail.game-index-1-id-9 {
  background-color: #EEEEEE;
}
.p-fs-1rem {
  font-size: 1rem !important;
}
.p-fs-1-5rem {
  font-size: 1.5rem !important;
}
.p-fs-2rem,
.p-icon-fs-2rem .p-button-icon {
  font-size: 2rem !important;
}
.p-fs-2-5rem {
  font-size: 2.5rem !important;
}
.p-fs-3rem {
  font-size: 3rem !important;
}
.btn-home {
  padding: 0;
}
.p-button-label {
  color: #000;
}
.p-button-label p {
  color: #000;
}
.customArrow .slick-prev:before,
.customArrow .slick-next:before {
  content: none;
}
.customArrow .slick-arrow img {
  width: 100%;
  height: initial;
}
.p-grid-page-home {
  height: 99vh;
  /* max-height: 100vh; */
  margin: 0.3vh !important;
}
.col-game-menu {
  color: #fff;
  padding: 0.8vh !important;
}
.col-game-menu a {
  text-decoration: none;
  color: #fff;
}
.col-game-menu a .box {
  background-color: #263248;
  height: 100%;
  width: 100%;
  /* margin:10px; */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.col-game-menu a .box h3 {
  margin: 0;
  margin-bottom: 1rem;
  font-weight: 300;
  font-size: 2.5vw;
}
.col-game-menu a .box img {
  width: 8vw;
}
.page-game-selector {
  background-color: #9DC8F5;
  min-height: 100vh;
}
.page-game-selector.game-no-8 {
  background-color: #fff;
}
.page-game-selector.game-no-8 .siteMenubar {
  background: #BBBBBB;
}
.page-game-detail .siteMenubar {
  background: #BBBBBB;
}
.page-game-detail .p-menubar-end {
  width: 100%;
  text-align: center;
}
.page-game-detail .game-instruction {
  font-size: 1.3rem;
  margin: 0;
  cursor: pointer;
  width: auto;
  display: inline-block;
}
.page-game-detail .game-instruction.active {
  background-color: midnightblue;
  color: #fff;
}
.app-title {
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
  font-size: 2rem;
}
.siteMenubar {
  border-radius: 0;
}
.siteMenubar.p-menubar {
  border: none;
}
.siteMenubar .p-menubar-button {
  display: none;
}
.slick-slider {
  overflow: hidden;
}
.SliderGameSelector {
  margin-top: 5%;
  /* .slick-slide {
    margin: 10px;
  } */
}
.SliderGameSelector .game-item {
  width: 800px !important;
}
.SliderGameSelector.float-init-slick-slide .slick-slide {
  float: initial;
}
.slick-slide {
  text-align: center;
  height: 100%;
}
.DialogFrameSettings {
  width: 50vw;
}
.FrameSettings {
  height: 280px;
}
.modal-dialog-buttons button {
  width: 100%;
}
.DialogFrameSettings .p-dialog-header-icons {
  display: none;
}
.modal-dialog-buttons .btn-label-white,
.DialogFrameSettings .p-dialog-footer .btn-label-white {
  background: #333333;
}
.modal-dialog-buttons .btn-label-white:hover,
.DialogFrameSettings .p-dialog-footer .btn-label-white:hover {
  background: #444 !important;
}
.wrapper-about p {
  margin: 0;
}
.game-item-header h3 {
  background-color: darkgray;
  margin: 0;
  padding: 1rem 0;
  font-size: 1.5rem;
}
.wrapper-game-type-selector {
  position: fixed;
  bottom: 18px;
  left: 40%;
  transform: translate(-50%, -50%);
}
.wrapper-game-type-selector button {
  margin-left: 10px;
  min-width: initial;
  max-width: initial;
  width: 30px;
  height: 30px;
  color: #000 !important;
}
.wrapper-game-type-selector button.selected {
  background-color: transparent !important;
  border: 1px solid #000 !important;
}
.button-play-game {
  position: fixed;
  bottom: -20px;
  left: 50%;
  transform: translate(-50%, -50%);
  text-decoration: none;
  /* button {
    background-color: transparent;
  } */
}
.img-preview {
  width: 100%;
}
.slick-track {
  min-width: 45298px !important;
}
.SliderImageGallery .slick-slide {
  height: auto;
  text-align: center;
  /* width: 100vw !important; */
}
.SliderImageGallery .slick-slide .gallery-item {
  display: block !important;
}
.SliderImageGallery .slick-slide .gallery-item img {
  margin: 0 auto;
}
.slick-arrow {
  z-index: 1;
}
.slick-arrow:before {
  color: #000;
}
.slick-arrow.slick-prev {
  left: 20px;
}
.slick-arrow.slick-next {
  right: 50px;
}
.slick-prev:before,
.slick-next:before {
  font-size: 50px;
}
.game-item {
  overflow: hidden;
  height: 405px;
}
.game-item .menuvideo {
  top: -41px;
  position: relative;
  width: 650px;
}
.game-item-header {
  z-index: 10;
  position: relative;
}
.game-item-selector {
  float: right;
  /* 
  height: 45px; */
  width: 192px;
}
.game-item-selector button {
  color: #444 !important;
  border-color: #444 !important;
}
.SliderFigyeldMegJolAKepet .slick-list {
  padding: 0 !important;
}
.SliderFigyeldMegJolAKepet .slick-slide.slick-active {
  width: 100vw !important;
}
.SliderFigyeldMegJolAKepet .question-item {
  min-height: 500px;
}
.SliderFigyeldMegJolAKepet .question-item .p-grid {
  min-height: 500px;
}
.sy-h-100p {
  height: 100%;
}
.sy-position-relative {
  position: relative;
}
.wrapper-btn-next-task {
  position: absolute;
  /* top: 50px; */
  right: 30px;
  top: -65px;
}
.wrapper-btn-next-task .btn-next-task {
  /* background-color: #fff !important; */
  top: 3px;
  z-index: 1;
  transform-origin: center;
  animation: anim-scaling 0.5s alternate infinite;
}
.wrapper-btn-next-task .btn-next-task img {
  width: auto;
  height: 50px;
}
.wrapper-btn-next-task .bg-of-btn-next-task {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.wrapper-btn-next-task.always_correct {
  top: 40%;
}
.wrapper-btn-next-task.always_correct .btn-next-task {
  animation: none !important;
}
@keyframes anim-scaling {
  0% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1.1);
  }
}
.task-wrapper-FigyeldMegJolAKepet .wrapper-main_image,
.task-wrapper-OlvasdFelHangosanASzoveget .wrapper-main_image {
  position: relative;
}
.task-wrapper-FigyeldMegJolAKepet .wrapper-main_image .p-col-3,
.task-wrapper-OlvasdFelHangosanASzoveget .wrapper-main_image .p-col-3 {
  position: relative;
}
.task-wrapper-FigyeldMegJolAKepet .wrapper-main_image .p-col-6,
.task-wrapper-OlvasdFelHangosanASzoveget .wrapper-main_image .p-col-6 {
  padding: 0;
}
.task-wrapper-FigyeldMegJolAKepet .wrapper-main_image img,
.task-wrapper-OlvasdFelHangosanASzoveget .wrapper-main_image img {
  display: inline-block;
  max-height: 500px;
}
.task-wrapper-FigyeldMegJolAKepet .btn-question-mark,
.task-wrapper-OlvasdFelHangosanASzoveget .btn-question-mark {
  position: absolute;
  right: 10%;
  top: 0;
}
.task-wrapper-FigyeldMegJolAKepet .btn-image,
.task-wrapper-OlvasdFelHangosanASzoveget .btn-image {
  position: absolute;
  left: 10%;
  top: 0;
}
.task-wrapper-FigyeldMegJolAKepet .question-item .p-col-6,
.task-wrapper-OlvasdFelHangosanASzoveget .question-item .p-col-6 {
  margin-top: -30px;
}
.task-wrapper-FigyeldMegJolAKepet .question-item .question-option .button-answer,
.task-wrapper-OlvasdFelHangosanASzoveget .question-item .question-option .button-answer {
  width: 90%;
  text-align: left;
}
.btn-answer-correct,
.btn-answer-incorrect {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.btn-answer-correct.active {
  background-color: #00FF00 !important;
}
.btn-answer-incorrect.active {
  background-color: #FF0000 !important;
  animation: shake 0.5s;
}
.incorrect-active {
  background-color: #FF0000 !important;
  animation: shake 0.5s;
}
.btn-selectable {
  border: 2px #ccc solid !important;
}
.btn-selected {
  border: 2px black solid !important;
}
@keyframes shake {
  0% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(-2px, 0);
  }
  20% {
    transform: translate(-6px, 0);
  }
  3% {
    transform: translate(6px, 0);
  }
  40% {
    transform: translate(2px, 0);
  }
  50% {
    transform: translate(-2px, 0);
  }
  60% {
    transform: translate(-6px, 0);
  }
  70% {
    transform: translate(6px, 0);
  }
  80% {
    transform: translate(2px, 0);
  }
  90% {
    transform: translate(-2px, 0);
  }
  100% {
    transform: translate(2px, 0);
  }
}
.option-image {
  /* width: 100%;
  height: auto; */
  width: 100%;
  object-fit: contain;
}
.question-item.valaszok_kozepen .option-image {
  height: 30vh;
  width: 100%;
  max-width: 100%;
  object-fit: contain;
}
.task-wrapper-MelyikKepTartozikASzohoz .question-item.valaszok_kozepen .option-image {
  height: 30vh;
  width: 100%;
}
.task-wrapper-MelyikTudUszni .option-image {
  height: 60vh !important;
}
.question-item.kerdes_bal_oldalon_valaszok_jobb_oldalon .question-option button {
  width: 100%;
}
.question-item.kerdes_bal_oldalon_valaszok_jobb_oldalon .question-option .option-image {
  height: 36vh;
  max-width: 100%;
}
.question-image {
  height: 36vh;
}
.question-item.kerdes_felul_valaszok_alul .p-grid-options {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  margin: 0 auto;
}
.question-item.kerdes_felul_valaszok_alul .option-image {
  height: 15vh;
  width: 100%;
  object-fit: contain;
}
.task-wrapper-FizesdKiPontosan .p-grid-options {
  position: relative !important;
}
.task-wrapper-NevezdMegAKepeket img {
  width: auto;
  height: 200px;
}
.task-wrapper-MinekAzArnyekatLatod img,
.task-wrapper-MitLatszAKepen img,
.task-wrapper-MiTortent img,
.task-wrapper-MitCsinal img,
.task-wrapper-MiFogTortenni img,
.task-wrapper-MiAKulonbsegKozottuk img,
.task-wrapper-MitCsinalAKepen img,
.task-wrapper-MikEzek img,
.task-wrapper-MiAFoglalkozasa img,
.task-wrapper-MitLatszAKepenNaming img,
.task-wrapper-MonddMegASzoSzinet img,
.task-wrapper-MonddErzelemmel img,
.task-wrapper-MilyenSportagVanAKepen img,
.task-wrapper-MitLatszAKepenNaming2 img,
.task-wrapper-MiAFurcsaEzenAKepen img,
.task-wrapper-KeresdAHibat img,
.task-wrapper-MitErezhet img,
.task-wrapper-MelyikOrszagJutEszedbeAKeprol img,
.task-wrapper-EttelMarIlyet img,
.task-wrapper-MiTortenikAKepen img,
.task-wrapper-NevezdMegHolVan img,
.task-wrapper-MeseljAKeprol img,
.task-wrapper-MitJelent img,
.task-wrapper-MelyikAHelyes img {
  width: auto;
  height: 500px;
}
.task-wrapper-MelyikKepTartozikASzohoz img,
.task-wrapper-MelyikKepTartozikAMondathoz img,
.task-wrapper-MelyikMondatTartozikAKephez img,
.task-wrapper-MelyikKeprolOlvastal img {
  width: auto;
  height: 200px;
}
.task-wrapper-MelyikPenztHallod .option-image,
.task-wrapper-MutasdMeg .option-image {
  /* width: auto; 
    height: 100px !important; */
}
.money-option {
  width: auto;
  height: 120px !important;
}
.task-wrapper-MennyiAzIdo #clockContainer {
  position: relative;
  margin: auto;
  height: 25vw;
  width: 25vw;
  /* background: url("https://media.geeksforgeeks.org/wp-content/uploads/20210302161254/imgonlinecomuaCompressToSizeOmNATjUMFKw-300x300.jpg") no-repeat; */
  background: url("images/clock.png") no-repeat;
  background-size: 100%;
}
.task-wrapper-MennyiAzIdo #hour,
.task-wrapper-MennyiAzIdo #minute {
  position: absolute;
  background: black;
  border-radius: 10px;
  transform-origin: bottom;
}
.task-wrapper-MennyiAzIdo #hour {
  width: 1.8%;
  height: 25%;
  top: 25%;
  left: 48.85%;
  opacity: 0.8;
}
.task-wrapper-MennyiAzIdo #minute {
  width: 1.6%;
  height: 30%;
  top: 19%;
  left: 48.9%;
  opacity: 0.8;
}
.task-wrapper-MennyiAzIdo .question-item.kerdes_bal_oldalon_valaszok_jobb_oldalon_egymas_alatt .btn-question-option {
  width: 100%;
  font-size: 1.5rem;
}
.task-wrapper-HovaIndulAVonat .question-item.kerdes_bal_oldalon_valaszok_jobb_oldalon_egymas_alatt .btn-question-option {
  width: 100%;
}
.draggable-item {
  margin: 10px;
  background-color: #fff;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.draggable-item img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}
.task-wrapper-RakdSorrendbeAKepeket .draggable-item {
  text-align: center;
  border: 2px solid black;
}
.task-wrapper-MelyikSzoIllikAMondatba h5 {
  font-size: 2rem;
}
.draggable-item.letter {
  /* background-color: #999; */
  padding: 10px 30px;
  min-height: 40px;
}
.minimize-letters .draggable-item.letter {
  min-width: initial !important;
  padding: 5px;
}
.minimize-letters .draggable-item.letter p {
  margin: 5px;
}
.words-destination.insentence {
  width: 200px;
  display: inline-block;
}
.words-destination {
  border-bottom: 5px solid #203c91;
  min-height: 100px;
}
.task-wrapper-AlkosdMegAHallottMondatot .words-destination {
  border-color: #000;
}
.word-item {
  background-color: #999;
  padding: 10px 30px;
  display: inline-block;
  margin: 10px;
}
.word-item.initial-state {
  position: initial !important;
  top: initial !important;
  left: initial !important;
}
.smooth-dnd-container {
  margin: 0 auto !important;
}
.destinationTable {
  margin: 0 150px 50px 150px;
}
.destinationTable th {
  font-weight: bold;
}
.destinationTable th,
.destinationTable td {
  padding: 10px 40px;
  background-color: #203c91;
  color: #fff;
}
.border-black {
  text-shadow: -1px -1px 0 #999, 1px -1px 0 #999, -1px 1px 0 #999, 1px 1px 0 #999;
}
.task-wrapper-MonddMegASzoSzinet .question-word {
  text-transform: uppercase;
  font-size: 3rem;
}
.FindAllInTable {
  margin: 0 auto;
  border-collapse: collapse;
}
.FindAllInTable td {
  width: 18rem;
  border: 2px solid #FF0000;
  padding: 20px 30px;
  font-size: 2.5rem;
  text-align: center;
  cursor: pointer;
}
.wrapper-keyboard {
  overflow: hidden;
}
.wrapper-keyboard .hg-standardBtn {
  background-color: #E7E7E7;
}
.wrapper-keyboard .hg-row:nth-child(1) .hg-button {
  background-color: #C9C2C1;
}
.wrapper-keyboard .hg-button-backspace {
  background-color: #C9C2C1;
  top: 2px;
  position: relative;
}
.wrapper-keyboard .hg-button-space {
  background-color: #C9C2C1;
}
.wrapper-keyboard.numbers {
  margin: 0 auto;
  width: 450px;
}
.wrapper-keyboard.numbers .p-col-8 {
  position: relative;
}
.wrapper-keyboard.numbers .btn-simulateBackspace {
  position: absolute;
  top: 15%;
  right: -50px;
}
.wrapper-keyboard.numbers .hg-theme-default {
  background-color: #fff;
}
.wrapper-keyboard.numbers .hg-button {
  background-color: #fff !important;
  border-radius: 0;
  border: 2px solid #999;
  height: 50px;
  padding-top: 10px;
}
.wrapper-keyboard.numbers .keyboard-input {
  height: 60px;
  width: 300px;
  border-bottom: 2px solid #000 !important;
}
.wrapper-keyboard.numbers .keyboard-input:active,
.wrapper-keyboard.numbers .keyboard-input:focus {
  border-bottom: 2px solid #000 !important;
  outline: none;
}
.wrapper-keyboard.numbers.correct .keyboard-input {
  background-color: #00FF00;
}
.wrapper-keyboard.numbers.incorrect .keyboard-input {
  background-color: #FF0000;
}
.wrapper-keyboard.words .hg-theme-default {
  background-color: #9F9D9B;
}
.wrapper-keyboard.words .hg-row:nth-child(3) {
  margin-left: -60px;
}
.wrapper-keyboard.words .hg-row:nth-child(4) {
  margin-left: -120px;
}
.wrapper-keyboard.words .hg-row:nth-child(5) {
  margin-left: -60px;
}
.wrapper-keyboard.words .hg-row:nth-child(5) .hg-button:nth-child(1) {
  width: 500px;
}
.wrapper-keyboard.words .hg-button-backspace span {
  display: none;
}
.wrapper-keyboard.words .hg-button-backspace::after {
  content: "⯇";
  font-size: 1.5rem;
}
.wrapper-keyboard.words.correct .grid-input {
  background-color: #00FF00;
}
.wrapper-keyboard.words.incorrect .grid-input {
  background-color: #FF0000;
}
.wrapper-keyboard .hg-theme-default .hg-row {
  display: block;
  text-align: center;
}
.wrapper-keyboard .hg-theme-default .hg-row .hg-button {
  display: inline-block;
  width: 60px;
  margin: 0 5px;
}
.wrapper-keyboard .hg-theme-default .hg-row .hg-button span {
  font-size: 1.5rem;
  font-weight: bold;
}
.wrapper-keyboard .keyboard-input {
  width: 100%;
  height: 100px;
  padding: 20px;
  font-size: 2rem;
  border: none;
  box-sizing: border-box;
  background: transparent;
}
.wrapper-keyboard .keyboard-input:active,
.wrapper-keyboard .keyboard-input:focus {
  border: none;
  outline: none;
}
.task-wrapper-FigyeldMegJolAKepetIrdLeAKepNevet .btn-next-frame,
.task-wrapper-FejezdBeAMondatot .btn-next-frame,
.task-wrapper-FejezdBe .btn-next-frame {
  position: absolute;
  top: 45%;
  right: 10%;
}
.task-wrapper-FigyeldMegJolAKepetIrdLeAKepNevet h1,
.task-wrapper-FejezdBeAMondatot h1,
.task-wrapper-FejezdBe h1 {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.task-wrapper-FigyeldMegJolAKepetIrdLeAKepNevet .question-image,
.task-wrapper-FejezdBeAMondatot .question-image,
.task-wrapper-FejezdBe .question-image {
  width: 300px;
  height: auto;
}
.task-wrapper-FejezdBeAMondatot .answer_line {
  color: #203c91;
  letter-spacing: -1px;
}
.task-wrapper-FejezdBe .answer_line,
.task-wrapper-FejezdBeAMondatot .wrapper-keyboard .answer_line {
  display: inline-block;
  border-bottom: solid #203c91 5px;
}
.task-wrapper-FejezdBe .answer_line {
  margin: 0 10px;
}
.d-none {
  opacity: 0;
  display: none;
}
.task-wrapper-JegyezdMegAPinKodot .wrapper-pin-question {
  position: relative;
}
.task-wrapper-JegyezdMegAPinKodot .wrapper-pin-question .pin-bg-img {
  width: 80%;
  height: auto;
}
.task-wrapper-JegyezdMegAPinKodot .wrapper-pin-question .pin-characters {
  position: absolute;
  top: 20%;
  right: 22%;
  color: blue;
  font-size: 5rem;
}
.task-wrapper-JegyezdMegAPinKodot .btn-next-frame,
.task-wrapper-JegyezdMegAPinKodot .btn-answer-check {
  position: absolute;
  top: 45%;
  right: 5%;
}
.task-wrapper-JegyezdMegAPinKodot .btn-image {
  position: absolute;
  top: 45%;
  left: 5%;
}
.blank_character {
  display: inline-block;
  width: 50px;
}
.img-right-icon {
  width: 40px;
  height: auto;
  position: fixed;
  bottom: 10px;
  left: 55%;
  transform: translate(-50%, -50%);
}
.Slider-JegyezdMegAPinKodot {
  min-height: 300px;
  /* .slick-list {
    padding: 0 !important;
  } */
}
.Slider-JegyezdMegAPinKodot .slide1 .pin-bg-img {
  margin: 0 auto;
}
.Slider-JegyezdMegAPinKodot .slide2 .wrapper-keyboard {
  display: none;
}
.Slider-JegyezdMegAPinKodot .slick-slide.slick-active .slide2 .wrapper-keyboard {
  display: inline-block;
}
.Slider-JegyezdMegAPinKodot .slide1,
.Slider-JegyezdMegAPinKodot .slide2 {
  position: relative;
  min-height: 300px;
}
.frame-NevezdMegTobbesszam {
  border: 2px solid #000;
  max-width: 500px;
  margin: 0 auto;
}
.frame-NevezdMegTobbesszam img {
  width: 100%;
}
.question-item.valaszok_kozepen {
  padding: 0 3rem;
}
.question-item.valaszok_kozepen .question-option button {
  width: 100%;
}
.question-item.kerdes_felul_valaszok_alul {
  padding: 0 3rem;
}
.question-item.kerdes_felul_valaszok_alul h5 {
  font-size: 1.5rem;
  margin: 0;
}
.question-item.kerdes_felul_valaszok_alul .question-image {
  height: 45vh;
  margin-bottom: 3rem;
}
.question-item.kerdes_felul_valaszok_alul .question-option button {
  width: 100%;
}
.question-item.kerdes_felul_valaszok_alul .question-option .p-button-label p {
  font-size: 1.5rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.wrapper-GameEndScreen {
  background-color: #00AA00;
  color: white;
  min-height: 85vh;
  margin-top: -3rem;
}
.wrapper-GameEndScreen.nomargintop {
  margin-top: 0 !important;
  margin: 0 auto;
  width: 100%;
}
.wrapper-GameEndScreen .button-replay {
  background-color: #fff;
}
.wrapper-GameEndScreen .button-replay img {
  width: 25px !important;
  height: initial !important;
}
.task-wrapper {
  margin-top: 3rem;
  position: relative;
  padding: 0 10px;
  min-height: 80vh;
}
.question-item .btn-volume-up {
  left: 10%;
  position: relative;
}
.task-wrapper-MelyikEsemenyhezIllikAKep .question-option .p-button-label p {
  margin: 0 !important;
}
.task-wrapper-RakdSorrendbeAKepeket .draggable-item {
  min-height: 550px;
  display: inline-block;
  position: relative;
  min-width: 300px;
}
.task-wrapper-RakdSorrendbeAKepeket .draggable-item p {
  font-size: 1.5rem;
  padding: 10px;
}
.task-wrapper-RakdSorrendbeAKepeket .draggable-item img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: 500px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.task-wrapper-RakdSorrendbeACselekveseket {
  margin-top: 8px;
  background: linear-gradient(90deg, #ddd 30vw, transparent 30vw);
  min-height: 89.7vh;
}
.task-wrapper-RakdSorrendbeACselekveseket h5 {
  font-size: 1.2rem;
}
.task-wrapper-RakdSorrendbeACselekveseket .btn-volume-up {
  position: absolute;
  right: 10%;
  top: 20%;
}
.task-wrapper-RakdSorrendbeACselekveseket .bg-arrow {
  background-image: url(images/ui_elements/arrow.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-position-y: 284px;
  background-position-x: 33vw;
  background-size: 67vw;
}
.task-wrapper-RakdSorrendbeACselekveseket .smooth-dnd-container {
  height: 300px;
}
.task-wrapper-RakdSorrendbeACselekveseket .smooth-dnd-draggable-wrapper {
  max-width: 440px;
}
.task-wrapper-RakdSorrendbeACselekveseket .draggable-item {
  margin: 0 auto;
  /* width: 200px; */
  text-align: center;
  background-color: transparent;
}
.task-wrapper-RakdSorrendbeACselekveseket .first-scene,
.task-wrapper-RakdSorrendbeACselekveseket .second-scene {
  height: 300px;
  border: 2px solid black;
  background-color: #fff;
}
.task-wrapper-RakdSorrendbeACselekveseket .question-image {
  height: 280px;
}
.task-wrapper-KirolBeszelunk .p-grid-main {
  /* min-height: 500px; */
}
.task-wrapper-KirolBeszelunk .p-grid-options {
  margin-top: 3rem;
}
.task-wrapper-KirolBeszelunk .btn-volume-up {
  position: initial;
}
.task-wrapper-KirolBeszelunk .question-option .p-button-label img {
  height: auto;
  width: 100%;
}
.question-item.valaszok_kozepen_ket_jo_valasz {
  /* .option-image {
    height: auto;
    max-height: 400px;
    width: 100%;
  } */
}
.question-item.valaszok_kozepen_ket_jo_valasz .p-grid-main {
  min-height: 500px;
}
.question-item.valaszok_kozepen_ket_jo_valasz .p-button {
  border-color: transparent;
}
.task-wrapper-NevezdMeg h5 {
  min-height: 30px;
  margin-bottom: 2rem !important;
}
.task-wrapper-NevezdMeg .question-option button {
  width: 60px !important;
}
.task-wrapper-NevezdMeg .question-option button p {
  margin: 0 !important;
}
.task-wrapper-NevezdMeg .p-grid-options {
  max-width: 500px;
  margin: 0 auto;
}
.task-wrapper-NevezdMegTobbesszam .frame-NevezdMegTobbesszam {
  min-height: 550px;
  display: inline-block;
  position: relative;
  min-width: 400px;
}
.task-wrapper-NevezdMegTobbesszam .frame-NevezdMegTobbesszam p {
  font-size: 1.5rem;
  padding: 10px;
  z-index: 10;
  position: relative;
  background-color: #fff;
}
.task-wrapper-NevezdMegTobbesszam .frame-NevezdMegTobbesszam img {
  height: auto;
  width: 100%;
  max-height: 450px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.task-wrapper-MonddMegASzoSzinet .question-word {
  margin: 10rem 0;
}
.task-wrapper-MonddErzelemmel p {
  margin-top: 0;
  font-size: 2rem;
}
.question-item.valaszok_kozepen p {
  font-size: 2rem;
}
.task-wrapper-MelyikKepTartozikASzohoz h5 {
  font-size: 1.5rem;
}
.task-wrapper-MelyikKepTartozikASzohoz .question-option p {
  font-size: 1rem;
}
.task-wrapper-MelyikAHelyes .question-option img {
  max-height: 400px;
}
.task-wrapper-MelyikSzoTartozikAKephez img {
  width: 300px;
  height: auto;
}
.question-item.kerdes_bal_oldalon_valaszok_jobb_oldalon_egymas_alatt .p-grid-main {
  padding-top: 30px;
}
.question-item.kerdes_bal_oldalon_valaszok_jobb_oldalon_egymas_alatt .question-option button {
  width: 200px;
  padding: 0 20px;
  margin: 10px 0;
  text-align: left;
  font-size: 1.2rem;
}
.task-wrapper-MelyikMondatTartozikAKephez .question-image {
  margin-top: 15px;
  width: 400px;
  height: auto;
}
.task-wrapper-MelyikMondatTartozikAKephez .question-option button {
  width: 600px !important;
  max-width: 100%;
}
.task-wrapper-MelyikKepTartozikAMondathoz h5,
.task-wrapper-MelyikKeprolOlvastal h5 {
  font-size: 2rem;
  margin-top: 1rem;
}
.task-wrapper-MelyikMondatAHelyes .question-option button {
  width: 600px !important;
  max-width: 100%;
}
.task-wrapper-MelyikMondatAHelyes .question-option button p {
  font-size: 1.3rem;
}
.task-wrapper-RakjRendet .question-image {
  max-width: 200px;
}
.task-wrapper-RakjRendet .letter-first {
  background-color: #999 !important;
}
.task-wrapper-RakjRendet .letter-last {
  background-color: #999 !important;
}
.task-wrapper-RakjRendet .letter,
.task-wrapper-RakjRendetOsszekeveredettBetuk .letter {
  background-color: violet;
  font-size: 1.2rem;
}
.task-wrapper-RakjRendet .question-image,
.task-wrapper-RakjRendetOsszekeveredettBetuk .question-image {
  width: 300px;
  height: auto;
}
.task-wrapper-RakjRendet .p-col-6,
.task-wrapper-RakjRendetOsszekeveredettBetuk .p-col-6 {
  position: relative;
}
.task-wrapper-RakjRendet .btn-volume-up,
.task-wrapper-RakjRendetOsszekeveredettBetuk .btn-volume-up {
  position: absolute;
  top: 10%;
  right: 25%;
}
.task-wrapper-AlkosdMegAHallottMondatot .question-image {
  width: 300px;
  height: auto;
}
.task-wrapper-AlkosdMegAHallottMondatot .p-col-6 {
  position: relative;
}
.task-wrapper-AlkosdMegAHallottMondatot .btn-volume-up {
  position: absolute;
  top: 0;
  right: 0;
}
.task-wrapper-FigyeldMegJolAKepetIrdLeAKepNevet .question-image {
  width: 400px;
  height: auto;
}
.task-wrapper-MelyikSzoIllikAMondatba .word-item {
  border: 2px solid #000;
  background-color: #fff;
  font-size: 1.8rem;
  font-weight: bold;
  padding: 0 20px;
}
.task-wrapper-MelyikSzoIllikAMondatba .words-destination {
  position: relative;
  bottom: -8px;
}
.task-wrapper-MelyikPenztHallod .question-option img,
.task-wrapper-MutasdMeg .question-option img {
  width: auto;
  height: 200px !important;
}
.task-wrapper-MutasdMeg h5 {
  font-size: 2rem;
  margin-top: 0;
}
.task-wrapper-MennyiAprodVan .money-option,
.task-wrapper-MennyiPapirpenzVanNalad .money-option,
.task-wrapper-MennyiPenzVanAPenztarcadban .money-option {
  width: 150px;
  height: auto !important;
  margin: 20px;
}
.task-wrapper-MennyiAprodVan .question-option button p,
.task-wrapper-MennyiPapirpenzVanNalad .question-option button p,
.task-wrapper-MennyiPenzVanAPenztarcadban .question-option button p {
  margin: 0 !important;
}
.task-wrapper-MennyiAprodVan h5,
.task-wrapper-MennyiPapirpenzVanNalad h5,
.task-wrapper-MennyiPenzVanAPenztarcadban h5 {
  display: inline-block;
  width: 500px;
}
.task-wrapper-MennyiPapirpenzVanNalad .money-option {
  width: 300px;
  height: auto !important;
  margin: 20px;
}
.task-wrapper-MennyiPapirpenzVanNalad h5 {
  display: inline-block;
  width: 800px;
}
.task-wrapper-MennyiPenzVanAPenztarcadban .money-option {
  width: initial !important;
  height: 150px !important;
  margin: 20px;
}
.task-wrapper-MennyiPenzVanAPenztarcadban h5 {
  display: inline-block;
  width: 800px;
}
.task-wrapper-FizesdKiPontosan .question-option {
  margin-top: 2rem;
}
.task-wrapper-FizesdKiPontosan .question-option button:not(.btn-selected) {
  border-color: transparent !important;
}
.task-wrapper-FizesdKiPontosan .p-button-label p {
  margin: 0 !important;
}
.task-wrapper-MennyiAzEredmeny h5 {
  font-size: 4rem !important;
  margin: 5rem 0 !important;
}
.task-wrapper-MennyiAzEredmeny button p {
  margin: 0 !important;
}
.task-wrapper-OlvasdFelHangosanASzoveget .wrapper-main_image .grid-main {
  min-height: 600px;
  /* background-color: yellow  ; */
  font-size: 2rem;
  line-height: 2.2rem;
}
.task-wrapper-OlvasdFelHangosanASzoveget .question-option {
  margin-top: 1rem;
}
.task-wrapper-OlvasdFelHangosanASzoveget .btn-question-mark {
  top: 40%;
}
.wrapper-AnimationAnswer {
  padding-top: 20px;
}
.task-wrapper-FigyeldMegJolAKepetIrdLeAKepNevet,
.task-wrapper-IrdLeAHallottSzot,
.task-wrapper-FejezdBeAMondatot,
.task-wrapper-FejezdBe {
  margin: 0;
}
.gallery-item img {
  max-width: 1300px;
}
.task-wrapper-FizesdKiPontosan .p-grid-main {
  position: relative;
}
.task-wrapper-FizesdKiPontosan .p-grid-main .btn-payment {
  background-color: #000;
  top: 5%;
  right: 10%;
  position: absolute;
}
.task-wrapper-FizesdKiPontosan .p-grid-main .btn-payment .p-button-label {
  color: #fff;
}
.task-wrapper-FizesdKiPontosan .p-grid-main h5 {
  display: inline-block;
}
.task-wrapper-FizesdKiPontosan .p-grid-main .btn-volume-up {
  top: 10px;
  left: 30px;
  position: relative;
}
.task-wrapper-MennyiAzEredmeny h5 {
  font-weight: normal;
}
.task-wrapper-MennyiAzEredmeny .question-option button {
  width: 200px !important;
}
.task-wrapper-MennyiAzEredmeny .question-option .p-button-label p {
  font-size: 2.5rem !important;
  color: #000;
}
.btn-simulateBackspace {
  font-size: 1.8rem;
}
.btn-label-white .p-button-label {
  color: #fff;
}
.wrapper-login {
  width: 100%;
}
.wrapper-LicenseText {
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
}
.task-wrapper-RakdSorrendbeAKepeket.correct .draggable-item {
  background-color: #00FF00;
}
.task-wrapper-RakdSorrendbeAKepeket.correct .draggable-item p {
  background-color: transparent !important;
}
.task-wrapper-RakdSorrendbeACselekveseket.correct .scene {
  background-color: #00FF00;
}
.task-wrapper-RakdSorrendbeACselekveseket.incorrect .scene {
  background-color: #FF0000;
}
.btn-incorrect {
  background-color: #FF0000 !important;
}
.wrapper-ShuffleLetters.correct .letter {
  background-color: #00FF00 !important;
}
.task-wrapper-AlkosdMegAHallottMondatot,
.task-wrapper-MelyikSzoIllikAMondatba {
  position: initial;
}
.task-wrapper-AlkosdMegAHallottMondatot .wrapper-btn-next-task,
.task-wrapper-MelyikSzoIllikAMondatba .wrapper-btn-next-task {
  top: 40px;
}
.task-wrapper-AlkosdMegAHallottMondatot .words-destination {
  margin-top: 3rem;
}
.task-wrapper-AlkosdMegAHallottMondatot .wrapper-AnimationAnswer.correct .word-item {
  background-color: #00FF00;
}
.task-wrapper-AlkosdMegAHallottMondatot .wrapper-AnimationAnswer.incorrect .word-item {
  background-color: #FF0000;
}
.wrapper-AnimationAnswer h5 {
  margin-top: 0;
  padding: 30px 0;
}
.wrapper-AnimationAnswer .words-destination {
  min-height: 40px;
}
.wrapper-AnimationAnswer.correct h5 {
  background-color: #00FF00;
}
.wrapper-AnimationAnswer.incorrect h5 {
  background-color: #FF0000;
}
.task-wrapper-MelyikSzoIllikAMondatba .word-item.animated {
  border: none;
  background-color: transparent;
}
.task-wrapper-MelyikSzoIllikAMondatba .word-item.animated p {
  margin: 0;
}
.task-wrapper-OlvasdFelHangosanASzoveget,
.task-wrapper.task-wrapper-FigyeldMegJolAKepet {
  margin-top: 0;
}
.task-wrapper-OlvasdFelHangosanASzoveget .wrapper-btn-next-task,
.task-wrapper.task-wrapper-FigyeldMegJolAKepet .wrapper-btn-next-task {
  top: -35px;
}
.task-wrapper-OlvasdFelHangosanASzoveget .wrapper-main_image {
  background: linear-gradient(90deg, #ffee00 92vw, transparent 92vw);
  min-height: 89.7vh;
}
.task-wrapper-OlvasdFelHangosanASzoveget .question-item {
  background: linear-gradient(90deg, transparent 5vw, cyan 5vw);
  min-height: 89.7vh;
}
.task-wrapper-OlvasdFelHangosanASzoveget .wrapper-btn-question-mark,
.task-wrapper-OlvasdFelHangosanASzoveget .wrapper-btn-image {
  position: relative;
  right: 8%;
}
.task-wrapper-OlvasdFelHangosanASzoveget .wrapper-btn-question-mark .btn-question-mark,
.task-wrapper-OlvasdFelHangosanASzoveget .wrapper-btn-image .btn-question-mark,
.task-wrapper-OlvasdFelHangosanASzoveget .wrapper-btn-question-mark .btn-image,
.task-wrapper-OlvasdFelHangosanASzoveget .wrapper-btn-image .btn-image {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
}
.task-wrapper-OlvasdFelHangosanASzoveget .wrapper-btn-question-mark .btn-image,
.task-wrapper-OlvasdFelHangosanASzoveget .wrapper-btn-image .btn-image {
  top: 0;
  left: 15%;
}
.task-wrapper-OlvasdFelHangosanASzoveget .wrapper-btn-question-mark .bg-rectangle,
.task-wrapper-OlvasdFelHangosanASzoveget .wrapper-btn-image .bg-rectangle {
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
  z-index: 0;
  margin: 6px 10px;
}
.task-wrapper-OlvasdFelHangosanASzoveget .wrapper-btn-question-mark .bg-rectangle.cyan,
.task-wrapper-OlvasdFelHangosanASzoveget .wrapper-btn-image .bg-rectangle.cyan {
  background-color: cyan;
}
.task-wrapper-OlvasdFelHangosanASzoveget .wrapper-btn-question-mark .bg-rectangle.yellow,
.task-wrapper-OlvasdFelHangosanASzoveget .wrapper-btn-image .bg-rectangle.yellow {
  background-color: #ffee00;
}
.task-wrapper-OlvasdFelHangosanASzoveget .wrapper-btn-image {
  right: 13%;
  top: 50px;
}
.task-wrapper-OlvasdFelHangosanASzoveget .wrapper-btn-image .bg-rectangle {
  left: 15%;
}
.task-wrapper-OlvasdFelHangosanASzoveget .question-item .button-answer:not(.active) {
  background-color: #fff;
}
.task-wrapper-OlvasdFelHangosanASzoveget .question-item .button-answer:not(.active):hover,
.task-wrapper-OlvasdFelHangosanASzoveget .question-item .button-answer:not(.active):focus {
  background-color: #fff !important;
}
/* 
.task-wrapper.task-wrapper-FigyeldMegJolAKepet {
  overflow: visible;
  .slick-slider {
    overflow: visible;
    .slick-list {
      overflow: visible;
    }
  }
} */
.draggable-item .overlay-bg {
  background-color: transparent;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.draggable-item p {
  position: relative;
  z-index: 2;
  background-color: #fff;
}
.task-wrapper-RakjRendet .draggable-item p,
.task-wrapper-RakjRendetOsszekeveredettBetuk .draggable-item p {
  background-color: transparent;
}
.task-wrapper-NevezdMeg .question-item.kerdes_felul_valaszok_alul .p-grid-options {
  position: relative;
}
.dnd-ghost-drop {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  transform: scale(1.1);
}
.task-wrapper-RakjRendetOsszekeveredettBetuk .smooth-dnd-container.horizontal {
  width: 100%;
  justify-content: center;
  display: flex;
}
.incorrect-word {
  background-color: #ff0000;
}
.task-wrapper-MennyiPenzVanAPenztarcadban h5 {
  width: 100%;
}
.task-wrapper-MutasdMeg .question-option img {
  max-height: 200px;
}
.task-wrapper-RakdSorrendbeAKepeket .smooth-dnd-draggable-wrapper {
  width: 300px;
}
.task-wrapper-RakdSorrendbeAKepeket .draggable-item img {
  width: 300px;
  height: 200px;
  /* height of container */
  object-fit: cover;
  object-position: 20% 5%;
  /* try 20px 10px */
}
.task-wrapper-RakjRendet .question-image,
.task-wrapper-RakjRendetOsszekeveredettBetuk .question-image {
  object-fit: cover;
  max-height: 50vh;
  /* max-height: 50vh; */
}
.btn-payment .btn-label {
  font-size: 1.5rem;
  position: relative;
  bottom: 4px;
}
.btn-payment .btn-img {
  position: relative;
  top: 4px;
  filter: invert(94%) sepia(0%) saturate(0%) hue-rotate(268deg) brightness(106%) contrast(106%);
}
.task-wrapper-MelyikKepTartozikASzohoz h5 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.task-wrapper-MelyikKepTartozikAMondathoz h5,
.task-wrapper-MelyikKeprolOlvastal h5 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
@media (min-width: 1800px) {
  .task-wrapper-MelyikKepTartozikASzohoz h5 {
    font-size: 2rem;
  }
  .siteMenubar .btn-home img {
    width: 5rem;
    padding: 1rem;
  }
  .wrapper-btn-next-task {
    top: -85px;
  }
  .wrapper-btn-next-task .bg-of-btn-next-task {
    width: 70px;
    height: 70px;
  }
  .wrapper-btn-next-task .btn-next-task {
    height: 60px;
  }
  .task-wrapper-RakdSorrendbeACselekveseket {
    /* 
    .bg-arrow {
      background-position-y: 350px;
      background-position-x: 72vh;
      background-size: 110rem;
    } */
  }
  .task-wrapper-RakdSorrendbeACselekveseket .first-scene,
  .task-wrapper-RakdSorrendbeACselekveseket .second-scene {
    height: 500px;
  }
  .task-wrapper-RakdSorrendbeACselekveseket .question-image {
    height: 480px;
  }
  .task-wrapper-RakdSorrendbeACselekveseket .smooth-dnd-draggable-wrapper {
    max-width: 600px;
  }
  .task-wrapper-MelyikSzoTartozikAKephez img,
  .task-wrapper-AlkosdMegAHallottMondatot .question-image,
  .task-wrapper-FigyeldMegJolAKepetIrdLeAKepNevet .question-image {
    width: 500px;
  }
  .task-wrapper-RakjRendet .question-image,
  .task-wrapper-RakjRendetOsszekeveredettBetuk .question-image {
    width: 500px;
    max-width: 500px;
  }
  .question-item.kerdes_bal_oldalon_valaszok_jobb_oldalon_egymas_alatt .question-option button {
    width: 500px;
    font-size: 2rem;
  }
  .destinationTable th,
  .destinationTable td {
    font-size: 2rem;
  }
  .question-item.valaszok_kozepen .option-image {
    height: 30vh;
  }
  .task-wrapper-JegyezdMegAPinKodot .wrapper-keyboard,
  .task-wrapper-MelyikSzamotHallod .wrapper-keyboard {
    margin-top: 10rem;
  }
  .Slider-JegyezdMegAPinKodot .slide1,
  .Slider-JegyezdMegAPinKodot .slide2 {
    min-height: 750px;
  }
  .task-wrapper-JegyezdMegAPinKodot .wrapper-pin-question .pin-characters {
    font-size: 8rem;
    right: 23%;
  }
  .task-wrapper-MennyiAzEredmeny h5 {
    margin-top: 15rem !important;
    font-size: 10rem !important;
  }
}
/* END of 1800px */
/* , .task-wrapper-NevezdMegAKepeket, .task-wrapper-MiAFurcsaEzenAKepen */
.SliderImageGallery .slick-slide {
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.SliderImageGallery .slick-slide.slick-active {
  opacity: 1;
}
@media (max-width: 1200px) {
  .question-item.kerdes_bal_oldalon_valaszok_jobb_oldalon .question-option .option-image {
    height: 30vh;
  }
  .FindAllInTable td {
    padding: 1rem 2rem;
    font-size: 1.5rem;
  }
  .destinationTable {
    margin: 0 20px 10px 20px;
  }
  .task-wrapper-FigyeldMegJolAKepet .question-item h2,
  .task-wrapper-OlvasdFelHangosanASzoveget .question-item h2 {
    font-size: 1.2rem;
  }
  .task-wrapper-FigyeldMegJolAKepet .question-item .question-option .button-answer,
  .task-wrapper-OlvasdFelHangosanASzoveget .question-item .question-option .button-answer {
    width: 20rem !important;
  }
  .gallery-item img {
    height: auto;
    max-height: 500px;
  }
  .question-item.kerdes_bal_oldalon_valaszok_jobb_oldalon .question-option {
    text-align: center;
    /* .option-image {          
        height: 180px;
      } */
  }
  .question-item.kerdes_felul_valaszok_alul .question-image {
    height: 300px;
  }
  .question-item.valaszok_kozepen {
    /* .option-image {
        max-height: 160px;
        width: auto;
    }  */
  }
  .smooth-dnd-container.horizontal .draggable-item {
    min-width: 200px;
  }
  .task-wrapper-RakdSorrendbeACselekveseket {
    /* .bg-arrow {
      background-position-y: 305px;
      background-position-x: 280px;
      background-size: 740px;
    } */
  }
  .task-wrapper-NevezdMegTobbesszam .frame-NevezdMegTobbesszam {
    min-height: 450px;
    min-width: 250px;
  }
  .task-wrapper-NevezdMegAKepeket .gallery-item img {
    max-height: 150px;
  }
  .smooth-dnd-container.horizontal .draggable-item {
    min-width: 100px;
  }
  .task-wrapper-JegyezdMegAPinKodot .wrapper-pin-question .pin-bg-img {
    width: 70%;
  }
  .task-wrapper-JegyezdMegAPinKodot .wrapper-pin-question .pin-characters {
    top: 10%;
    right: 24%;
    font-size: 3.2rem;
  }
  .task-wrapper-JegyezdMegAPinKodot .btn-next-frame {
    right: 50px;
  }
  .task-wrapper-MelyikTudUszni .option-image {
    height: 30vh !important;
  }
  .task-wrapper-MelyikKepTartozikASzohoz .question-item.valaszok_kozepen .option-image {
    height: 20vh;
  }
  .task-wrapper-MelyikAHelyes .question-option img {
    max-height: 250px;
  }
  .task-wrapper-AlkosdMegAHallottMondatot .word-item {
    padding: 10px;
  }
  .task-wrapper-KeresdMegAzOsszesSzot .FindAllInTable td {
    width: 8rem;
    padding: 0.5rem;
    font-size: 1rem;
  }
  .task-wrapper-OlvasdFelHangosanASzoveget .wrapper-main_image {
    background: linear-gradient(90deg, #ffee00 90vw, transparent 90vw);
  }
  .task-wrapper-OlvasdFelHangosanASzoveget .question-item {
    background: linear-gradient(90deg, transparent 10vw, cyan 10vw);
  }
  .task-wrapper-MelyikSzoTartozikAKephez img {
    max-height: 300px;
    width: auto;
  }
  .task-wrapper-MutasdMeg h5 {
    margin-bottom: 0.5rem;
  }
  .task-wrapper {
    margin-top: 1rem;
  }
  .task-wrapper-MennyiPenzVanAPenztarcadban .money-option {
    height: 90px !important;
  }
  .money-option {
    height: 100px !important;
  }
  .task-wrapper-HovaIndulAVonat .question-item.kerdes_bal_oldalon_valaszok_jobb_oldalon_egymas_alatt .btn-question-option {
    width: 50%;
  }
}
/* END of 1200px */
.btn-fullscreen,
.btn-home-mobile {
  display: none;
  padding: 0 !important;
}
.btn-home-mobile {
  position: relative;
  float: right;
}
@media (max-width: 768px) {
  .app-title {
    font-size: 1.5rem;
  }
  .question-item.kerdes_bal_oldalon_valaszok_jobb_oldalon .question-option .option-image {
    height: 30vh;
  }
  .col-game-menu a .box img {
    width: 15vw;
  }
  .col-game-menu a .box h3 {
    font-size: 1.2rem !important;
  }
  .img-right-icon {
    left: 65%;
    transform: translate(-50%, -50%);
  }
  .FindAllInTable td {
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
  }
  .task-wrapper-KeresdMegAzOsszesSzot .FindAllInTable td {
    width: 5rem;
    padding: 0.3rem;
    font-size: 0.8rem;
  }
  .task-wrapper-FigyeldMegJolAKepet .wrapper-main_image img,
  .task-wrapper-OlvasdFelHangosanASzoveget .wrapper-main_image img {
    max-height: 350px;
  }
  .task-wrapper-FigyeldMegJolAKepet .wrapper-main_image p,
  .task-wrapper-OlvasdFelHangosanASzoveget .wrapper-main_image p {
    font-size: 1.5rem;
  }
  .task-wrapper-FigyeldMegJolAKepet .question-item .p-grid,
  .task-wrapper-OlvasdFelHangosanASzoveget .question-item .p-grid {
    min-height: 370px;
  }
  .question-item.kerdes_bal_oldalon_valaszok_jobb_oldalon .question-option {
    /* .option-image {
        height: 120px;
      } */
  }
  .task-wrapper-RakdSorrendbeAKepeket .smooth-dnd-container.horizontal .draggable-item {
    min-height: 450px;
  }
  .task-wrapper-RakdSorrendbeAKepeket .smooth-dnd-container.horizontal .draggable-item p {
    font-size: 1.2rem;
    position: relative;
    z-index: 10;
    background-color: #fff;
  }
  .smooth-dnd-container.horizontal .draggable-item {
    min-width: 60px;
  }
  .task-wrapper-RakdSorrendbeACselekveseket .bg-arrow {
    background-position-y: 320px;
    background-position-x: 0;
    background-size: 100vw;
  }
  .task-wrapper-NevezdMegAKepeket .gallery-item {
    padding: 0 3rem;
  }
  .task-wrapper-NevezdMegAKepeket .gallery-item img {
    max-height: 110px;
  }
  .wrapper-keyboard .hg-theme-default .hg-row .hg-button {
    width: 50px;
  }
  .task-wrapper-MennyiPapirpenzVanNalad h5,
  .task-wrapper-MennyiPenzVanAPenztarcadban h5 {
    width: 600px;
  }
  .task-wrapper-MennyiPapirpenzVanNalad .money-option,
  .task-wrapper-MennyiPenzVanAPenztarcadban .money-option {
    width: 200px;
  }
  .task-wrapper-MennyiPenzVanAPenztarcadban .money-option {
    width: initial !important;
    height: 100px !important;
    margin: 10px;
  }
  .task-wrapper-FizesdKiPontosan .money-option {
    height: 75px !important;
  }
  .destinationTable {
    margin: 0 auto;
  }
  .task-wrapper-MelyikSzamotHallod .wrapper-keyboard {
    margin-left: -50px;
  }
  .task-wrapper-JegyezdMegAPinKodot .wrapper-pin-question .pin-characters {
    top: 25%;
    right: 25%;
    font-size: 2rem;
  }
  .task-wrapper-MelyikAHelyes .question-item.valaszok_kozepen .option-image {
    height: 15vh;
  }
  .question-item.valaszok_kozepen .option-image {
    height: 20vh;
  }
  .task-wrapper-FigyeldMegJolAKepetIrdLeAKepNevet .question-image {
    width: 300px;
  }
  .task-wrapper-FigyeldMegJolAKepetIrdLeAKepNevet .btn-next-frame,
  .task-wrapper-FejezdBeAMondatot .btn-next-frame,
  .task-wrapper-FejezdBe .btn-next-frame {
    top: 30%;
  }
  .task-wrapper-KeresdMegAzOsszesSzot,
  .task-wrapper-KeresdMegAzOsszesBetut {
    margin-top: 1rem;
  }
  .task-wrapper-OlvasdFelHangosanASzoveget .slick-track {
    min-height: initial;
    height: 72vh;
  }
  .task-wrapper-OlvasdFelHangosanASzoveget .wrapper-main_image {
    min-height: 100%;
    background: linear-gradient(90deg, #ffee00 85vw, transparent 85vw);
  }
  .task-wrapper-OlvasdFelHangosanASzoveget .slick-slide .question-item {
    height: 100%;
  }
  .task-wrapper-OlvasdFelHangosanASzoveget .slick-slide > div {
    height: 100%;
  }
  .task-wrapper-OlvasdFelHangosanASzoveget .question-item {
    background: linear-gradient(90deg, transparent 12vw, cyan 12vw);
  }
  .task-wrapper-RakjRendet .question-image,
  .task-wrapper-RakjRendetOsszekeveredettBetuk .question-image {
    width: 150px;
    /* max-height: 50vh; */
  }
  .task-wrapper-AlkosdMegAHallottMondatot .btn-volume-up {
    top: -45px;
  }
  .wrapper-keyboard .keyboard-input {
    padding: 0 20px;
    height: 25px;
  }
  .wrapper-keyboard.numbers .btn-simulateBackspace {
    right: 0;
  }
  .task-wrapper-FizesdKiPontosan .question-option {
    margin: 0;
  }
  .task-wrapper-RakdSorrendbeACselekveseket {
    background: linear-gradient(180deg, #ddd 150px, transparent 150px);
  }
  .task-wrapper-RakdSorrendbeACselekveseket .question-image {
    height: auto;
    max-width: 120px;
    max-height: 120px;
  }
  .task-wrapper-RakdSorrendbeACselekveseket .smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
    display: inline-block;
    margin: 10px;
  }
  .task-wrapper-RakdSorrendbeACselekveseket .smooth-dnd-container {
    height: initial;
    text-align: center;
  }
  .task-wrapper-RakdSorrendbeACselekveseket .first-scene,
  .task-wrapper-RakdSorrendbeACselekveseket .second-scene {
    height: 100px;
  }
  .btn-fullscreen,
  .btn-home-mobile {
    display: block;
  }
  .btn-home {
    display: none;
  }
  .SliderGameSelector {
    margin-top: 0;
  }
  .page-game-detail .game-instruction {
    font-size: 1rem;
    padding: 10px 0;
  }
  .game-item-selector button {
    padding: 5px 10px;
  }
  .task-wrapper-OlvasdFelHangosanASzoveget .wrapper-main_image .grid-main {
    min-height: auto;
  }
  .task-wrapper-OlvasdFelHangosanASzoveget .wrapper-main_image .grid-main p {
    line-height: 1.2;
  }
  .task-wrapper-OlvasdFelHangosanASzoveget .question-item {
    min-height: auto;
  }
  .p-grid-page-home {
    height: 90vh;
  }
  .DialogFrameSettings {
    width: 80vw;
  }
  .DialogFrameSettings h1 {
    font-size: 1.2rem;
  }
  .DialogFrameSettings .p-dialog-header {
    padding: 0 0.5rem;
  }
  .DialogFrameSettings .p-dialog-footer {
    padding: 0.5rem;
  }
  .wrapper-btn-next-task {
    top: -45px;
  }
  .money-option {
    height: 80px !important;
  }
}
/* END of 768px */
@media (max-width: 768px) and (orientation: landscape) {
  .col-game-menu {
    width: 25%;
  }
  .game-item {
    height: 155px;
  }
  .game-item .menuvideo,
  .game-item .video-preview {
    width: 250px;
    top: -16px;
  }
  .task-wrapper-OlvasdFelHangosanASzoveget .wrapper-btn-image {
    top: 0;
  }
  .task-wrapper-FigyeldMegJolAKepet .question-item,
  .task-wrapper-OlvasdFelHangosanASzoveget .question-item {
    padding-top: 45px;
  }
  .task-wrapper-MelyikMondatAHelyes .question-option button p {
    margin: 0;
    font-size: 1rem;
  }
  .question-item.valaszok_kozepen .option-image {
    height: 20vh !important;
  }
  .task-wrapper-MelyikKepTartozikAMondathoz h5,
  .task-wrapper-MelyikKeprolOlvastal h5,
  .task-wrapper-MelyikKepTartozikASzohoz h5 {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  .task-wrapper-MelyikMondatTartozikAKephez .question-image {
    margin-top: 0;
    width: 150px;
  }
  .task-wrapper-AlkosdMegAHallottMondatot .question-image {
    width: 130px;
  }
  .task-wrapper-AlkosdMegAHallottMondatot .words-destination {
    margin-top: 1rem;
  }
  .p-grid-page-home {
    height: 92vh;
  }
  .col-game-menu a .box h3 {
    font-size: 1rem !important;
  }
  .col-game-menu a .box img {
    width: 8vw;
  }
  .task-wrapper-MelyikPenztHallod .question-option img,
  .task-wrapper-MutasdMeg .question-option img {
    height: 80px !important;
  }
  .question-item.kerdes_felul_valaszok_alul .question-option .p-button-label p {
    font-size: 1rem;
  }
  .task-wrapper-MennyiAprodVan .money-option,
  .task-wrapper-MennyiPenzVanAPenztarcadban .money-option {
    width: 80px !important;
    height: auto !important;
  }
  .task-wrapper-MennyiPapirpenzVanNalad h5,
  .task-wrapper-MennyiPenzVanAPenztarcadban h5 {
    width: 560px;
  }
  .task-wrapper-MennyiPapirpenzVanNalad .money-option {
    width: 150px !important;
    height: auto !important;
  }
  .btn-payment {
    right: 0 !important;
  }
  .btn-payment .btn-label {
    font-size: 1rem;
    bottom: 6px;
  }
  .p-button-label p {
    font-size: 1rem;
    margin: 0.5rem;
  }
  .destinationTable th,
  .destinationTable td {
    padding: 5px;
  }
  .task-wrapper-MelyikSzoTartozikAKephez img {
    max-height: 200px;
  }
  .task-wrapper-MelyikMondatTartozikAKephez .question-option button {
    width: 300px !important;
  }
  .task-wrapper-MelyikSzamotHallod .wrapper-keyboard {
    margin-left: -68px;
  }
  .task-wrapper-MelyikSzamotHallod .wrapper-keyboard #keyboard-input {
    position: relative;
    left: 70px;
  }
  .wrapper-keyboard.numbers .hg-button {
    height: 32px;
    padding-top: 0;
  }
  .task-wrapper {
    margin-top: 0;
  }
  .wrapper-keyboard.words .hg-theme-default .hg-row .hg-button {
    width: 30px;
    height: 30px;
    padding-top: 0;
  }
  .wrapper-keyboard.words .btn-speaker,
  .wrapper-keyboard.words .btn-image {
    position: absolute;
    right: 0;
    top: 0;
  }
  .wrapper-keyboard.words .btn-answer-check {
    padding: 0;
  }
  .task-wrapper-FigyeldMegJolAKepetIrdLeAKepNevet h1,
  .task-wrapper-FejezdBeAMondatot h1,
  .task-wrapper-FejezdBe h1 {
    font-size: 1.5rem;
  }
}
@media (max-width: 576px) {
  #root {
    overflow: auto;
  }
  .game-item {
    width: 100% !important;
  }
  .slick-list {
    max-height: 100vh;
  }
  .slick-slide {
    width: 100vw;
    margin: 0;
  }
  .slick-track {
    width: 3298px !important;
  }
  .DialogFrameSettings {
    width: 80vw;
  }
  .wrapper-game-type-selector {
    left: 30%;
  }
  .task-wrapper-FigyeldMegJolAKepet .question-item,
  .task-wrapper-OlvasdFelHangosanASzoveget .question-item {
    padding-top: 100px;
  }
  .task-wrapper-RakdSorrendbeAKepeket .smooth-dnd-draggable-wrapper {
    width: 30px;
  }
  .task-wrapper-RakdSorrendbeAKepeket .draggable-item img {
    width: 30px;
  }
  .question-item.kerdes_bal_oldalon_valaszok_jobb_oldalon .question-option .option-image {
    height: initial;
    height: 100px !important;
  }
  .SliderGameSelector .game-item {
    width: 100% !important;
  }
  .game-item {
    height: 155px;
  }
  .game-item .menuvideo,
  .game-item .video-preview {
    width: 250px;
    top: -16px;
  }
  .FindAllInTable td {
    padding: 0.2rem 0.5rem;
    font-size: 0.8rem;
  }
  .task-wrapper-KeresdMegAzOsszesSzot .FindAllInTable td {
    font-size: 0.6rem;
  }
  .task-wrapper-FigyeldMegJolAKepet .wrapper-main_image img,
  .task-wrapper-OlvasdFelHangosanASzoveget .wrapper-main_image img {
    max-height: 150px;
  }
  .task-wrapper-FigyeldMegJolAKepet .wrapper-main_image p,
  .task-wrapper-OlvasdFelHangosanASzoveget .wrapper-main_image p {
    font-size: 1.1rem;
    padding: 20px;
  }
  .task-wrapper-FigyeldMegJolAKepet .question-item .p-grid,
  .task-wrapper-OlvasdFelHangosanASzoveget .question-item .p-grid {
    min-height: 170px;
  }
  .task-wrapper-FigyeldMegJolAKepet .question-item .p-grid .button-answer,
  .task-wrapper-OlvasdFelHangosanASzoveget .question-item .p-grid .button-answer {
    width: 12rem!important;
  }
  .gallery-item img {
    max-height: 100px;
  }
  .question-item.kerdes_bal_oldalon_valaszok_jobb_oldalon .question-option {
    /* .option-image {
        height: 60px;
      } */
  }
  .question-item.kerdes_felul_valaszok_alul .question-option .p-button-label p {
    font-size: 0.7rem;
  }
  /* .question-item.kerdes_felul_valaszok_alul .option-image {
    height: 50px;
  } */
  /* .question-item.valaszok_kozepen .option-image {
    max-height: 100px;
  } */
  .task-wrapper-RakdSorrendbeAKepeket .smooth-dnd-container.horizontal .draggable-item p {
    font-size: 0.8rem;
  }
  .task-wrapper-RakdSorrendbeAKepeket .smooth-dnd-container.horizontal .draggable-item {
    min-height: 300px;
  }
  /* 
  .task-wrapper-RakdSorrendbeAKepeket .draggable-item img {
    width: 200px;
  } */
  .task-wrapper-RakdSorrendbeACselekveseket .btn-volume-up {
    top: 0;
  }
  .question-item.kerdes_felul_valaszok_alul {
    padding: 0 1rem;
  }
  .task-wrapper-NevezdMegTobbesszam .frame-NevezdMegTobbesszam {
    min-width: 120px;
  }
  .task-wrapper-NevezdMegAKepeket .gallery-item img {
    max-height: 45px;
  }
  .question-item.valaszok_kozepen p {
    font-size: 1rem;
  }
  .task-wrapper-MelyikSzoTartozikAKephez img {
    width: 150px;
  }
  .question-item.kerdes_bal_oldalon_valaszok_jobb_oldalon_egymas_alatt .question-option button {
    font-size: 0.8rem;
    width: 150px;
  }
  .task-wrapper-MelyikMondatAHelyes .question-option button {
    width: 300px !important;
  }
  .task-wrapper-RakjRendet .btn-volume-up,
  .task-wrapper-RakjRendetOsszekeveredettBetuk .btn-volume-up {
    top: 0;
    right: 0;
  }
  .draggable-item.letter {
    padding: 5px;
  }
  .task-wrapper-RakjRendet .letter,
  .task-wrapper-RakjRendetOsszekeveredettBetuk .letter {
    margin: 0.5rem 0;
  }
  .task-wrapper-AlkosdMegAHallottMondatot .btn-volume-up {
    top: -65px;
  }
  .word-item {
    font-size: 0.8rem;
  }
  .wrapper-keyboard .hg-theme-default .hg-row .hg-button {
    width: 30px;
    margin: 0 2px;
  }
  .wrapper-keyboard.words .hg-row:nth-child(5) .hg-button:nth-child(1) {
    width: 200px;
  }
  .task-wrapper-MennyiAprodVan h5,
  .task-wrapper-MennyiPapirpenzVanNalad h5,
  .task-wrapper-MennyiPenzVanAPenztarcadban h5 {
    width: 300px;
  }
  .task-wrapper-MennyiAprodVan .money-option,
  .task-wrapper-MennyiPapirpenzVanNalad .money-option,
  .task-wrapper-MennyiPenzVanAPenztarcadban .money-option {
    width: 60px !important;
    height: auto !important;
  }
  .task-wrapper-FizesdKiPontosan .money-option {
    height: 30px !important;
  }
  .destinationTable td,
  .destinationTable th {
    padding: 3px 8px;
    font-size: 0.8rem;
  }
  .task-wrapper-JegyezdMegAPinKodot .wrapper-pin-question .pin-characters {
    font-size: 1rem ;
  }
  .task-wrapper-MennyiAzEredmeny .question-option button {
    width: 80px !important;
  }
  .task-wrapper-MennyiAzEredmeny .question-option button .p-button-label p {
    font-size: 1rem !important;
  }
  .wrapper-keyboard.numbers .keyboard-input {
    width: 200px;
  }
  .wrapper-keyboard .hg-theme-default .hg-row .hg-button {
    width: 30px;
  }
  /* 
  .react-simple-keyboard  {
    margin-left: -50px;
  } */
  .wrapper-keyboard.numbers .btn-simulateBackspace {
    right: 50px;
  }
  /* 
  .task-wrapper-JegyezdMegAPinKodot {
    .wrapper-keyboard.numbers .keyboard-input {
      margin-left: -100px;
    } 
  } */
  .question-item.kerdes_felul_valaszok_alul .question-image {
    height: 200px;
  }
  .question-item.kerdes_felul_valaszok_alul .option-image {
    height: 10vh;
  }
  .task-wrapper-MelyikTudUszni .option-image {
    height: initial !important;
  }
  .task-wrapper-NevezdMegTobbesszam .frame-NevezdMegTobbesszam {
    min-height: 250px;
  }
  .task-wrapper-MelyikKepTartozikASzohoz .question-item.valaszok_kozepen .option-image {
    height: 10vh;
  }
  .question-item.valaszok_kozepen .option-image {
    height: 100px !important;
  }
  .wrapper-keyboard.words .hg-row {
    margin-left: 0 !important;
  }
  .col-components {
    text-align: center;
  }
  .task-wrapper-OlvasdFelHangosanASzoveget .wrapper-main_image {
    background: linear-gradient(90deg, #ffee00 80vw, transparent 80vw);
  }
  .task-wrapper-OlvasdFelHangosanASzoveget .question-item {
    background: linear-gradient(90deg, transparent 20vw, cyan 20vw);
  }
  .task-wrapper-RakjRendet .question-image,
  .task-wrapper-RakjRendetOsszekeveredettBetuk .question-image {
    width: 120px;
  }
  .smooth-dnd-container.horizontal .draggable-item {
    min-width: 40px;
  }
  .task-wrapper-MelyikSzoIllikAMondatba h5 {
    font-size: 1.8rem;
    padding: 0;
  }
  .task-wrapper-JegyezdMegAPinKodot .slick-slide {
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  .task-wrapper-JegyezdMegAPinKodot .slick-slide.slick-active {
    opacity: 1;
  }
  .task-wrapper-JegyezdMegAPinKodot .wrapper-keyboard.numbers .keyboard-input {
    margin-left: -220px;
  }
  .wrapper-keyboard.numbers .btn-simulateBackspace {
    position: relative;
    right: 0;
    z-index: 1;
  }
  .wrapper-keyboard.numbers .react-simple-keyboard {
    margin-left: -130px;
  }
  .task-wrapper-JegyezdMegAPinKodot .btn-image {
    left: -50px;
  }
  .task-wrapper-JegyezdMegAPinKodot .btn-answer-check {
    right: -50px;
  }
  .task-wrapper-FizesdKiPontosan .p-grid-main .btn-payment {
    position: fixed;
    left: 0;
    top: 20%;
  }
  .task-wrapper-RakdSorrendbeACselekveseket .bg-arrow {
    background-position-y: 400px;
  }
  .btn-payment .btn-label {
    font-size: 1rem;
  }
  .btn-payment .btn-img {
    width: 20px;
    top: 2px;
  }
  .task-wrapper-MelyikSzamotHallod .wrapper-keyboard #keyboard-input {
    left: 50px;
    position: absolute;
  }
}
/* END of 576px */
@media (max-width: 576px) and (orientation: landscape) {
  .col-game-menu a .box h3 {
    font-size: 1rem !important;
  }
  .col-game-menu a .box img {
    width: 12vh;
  }
  .game-item {
    height: 132px;
  }
  .game-item .menuvideo,
  .game-item .video-preview {
    width: 215px;
    top: -15px;
  }
  .task-wrapper-FigyeldMegJolAKepet .question-item .p-grid .button-answer,
  .task-wrapper-OlvasdFelHangosanASzoveget .question-item .p-grid .button-answer {
    width: 20rem !important;
  }
}
@media (max-width: 320px) {
  .col-game-menu h3 {
    font-size: 0.8rem !important;
  }
  .game-item .menuvideo,
  .game-item .video-preview {
    width: 140px;
    top: -9px;
  }
  .game-item {
    height: 87px;
  }
  .FindAllInTable td {
    padding: 0.2rem 0.3rem;
  }
  .task-wrapper-KeresdMegAzOsszesSzot .FindAllInTable td {
    padding: 0.05rem;
    font-size: 0.5rem;
  }
  .task-wrapper-FigyeldMegJolAKepet .wrapper-main_image img,
  .task-wrapper-OlvasdFelHangosanASzoveget .wrapper-main_image img {
    max-height: 120px;
  }
  .task-wrapper-FigyeldMegJolAKepet .btn-question-mark,
  .task-wrapper-OlvasdFelHangosanASzoveget .btn-question-mark {
    right: 20%;
  }
  .task-wrapper-FigyeldMegJolAKepet .question-item .p-grid .button-answer,
  .task-wrapper-OlvasdFelHangosanASzoveget .question-item .p-grid .button-answer {
    width: 8rem!important;
  }
  .task-wrapper-FigyeldMegJolAKepet .btn-arrow-right,
  .task-wrapper-OlvasdFelHangosanASzoveget .btn-arrow-right {
    position: relative;
    right: 20px;
  }
  .task-wrapper-FigyeldMegJolAKepet .question-item,
  .task-wrapper-OlvasdFelHangosanASzoveget .question-item {
    padding-top: 100px;
  }
  .task-wrapper-OlvasdFelHangosanASzoveget .p-component {
    /* margin-right: -40px; */
  }
  .task-wrapper-OlvasdFelHangosanASzoveget .wrapper-main_image p {
    font-size: 0.9rem;
  }
  /* .question-item.kerdes_bal_oldalon_valaszok_jobb_oldalon .question-option .option-image {
    height: 30px;
  } */
  .question-item.kerdes_felul_valaszok_alul .question-image {
    height: 100px;
  }
  /* .question-item.valaszok_kozepen .option-image {
    max-height: 60px;
  } */
  .task-wrapper-RakdSorrendbeAKepeket .smooth-dnd-container.horizontal .draggable-item {
    margin: 3px;
  }
  .task-wrapper-NevezdMegTobbesszam .frame-NevezdMegTobbesszam {
    max-width: 100px;
  }
  .task-wrapper-NevezdMegTobbesszam .frame-NevezdMegTobbesszam img {
    max-height: 300px;
  }
  .task-wrapper-NevezdMegAKepeket .gallery-item {
    padding: 0 3rem;
  }
  .task-wrapper-NevezdMegAKepeket .gallery-item img {
    max-height: 30px;
  }
  .question-item.valaszok_kozepen p {
    font-size: 0.8rem;
  }
  .question-item.kerdes_bal_oldalon_valaszok_jobb_oldalon_egymas_alatt .question-option button {
    width: 120px;
  }
  .task-wrapper-MelyikMondatAHelyes .question-option button p {
    font-size: 1rem;
  }
  .smooth-dnd-container.horizontal .draggable-item {
    min-width: 30px;
  }
  .word-item {
    padding: 10px;
  }
  .task-wrapper-FigyeldMegJolAKepetIrdLeAKepNevet .question-image {
    width: 200px;
  }
  .wrapper-keyboard .hg-theme-default .hg-row .hg-button {
    width: 22px;
  }
  .react-simple-keyboard {
    margin: 0;
  }
  .wrapper-keyboard .hg-theme-default .hg-row .hg-button span,
  .wrapper-keyboard.words .hg-button-backspace::after {
    font-size: 1rem;
  }
  .hg-theme-default .hg-button {
    height: 30px;
  }
  .wrapper-keyboard.words .hg-row {
    margin-left: 0 !important;
  }
  .task-wrapper-FigyeldMegJolAKepetIrdLeAKepNevet .p-col-2 .p-icon-fs-2rem:not(.btn-image) {
    position: relative;
    right: 10px;
  }
  .task-wrapper-FizesdKiPontosan .p-grid-main .btn-payment {
    position: fixed;
    left: 0;
    top: 20%;
  }
  .destinationTable td,
  .destinationTable th {
    font-size: 0.6rem;
    padding: 8px;
  }
  .task-wrapper-OlvasdFelHangosanASzoveget .wrapper-main_image {
    background: linear-gradient(90deg, #ffee00 75vw, transparent 75vw);
  }
  .task-wrapper-OlvasdFelHangosanASzoveget .question-item {
    background: linear-gradient(90deg, transparent 25vw, cyan 25vw);
  }
  .task-wrapper-MelyikSzoIllikAMondatba h5 {
    font-size: 1.5rem;
    padding: 0;
  }
  .task-wrapper-MelyikSzoIllikAMondatba .word-item p {
    margin: 5px;
  }
  .task-wrapper-JegyezdMegAPinKodot .wrapper-pin-question .pin-characters {
    top: 10%;
    right: 23%;
  }
  .task-wrapper-JegyezdMegAPinKodot .wrapper-keyboard.numbers .keyboard-input {
    margin-left: -220px;
  }
  .task-wrapper-MelyikSzamotHallod .wrapper-keyboard.numbers .keyboard-input {
    margin-left: -60px;
  }
  .wrapper-keyboard.numbers .btn-simulateBackspace {
    right: 120px;
  }
  .wrapper-keyboard.numbers .btn-simulateBackspace {
    position: relative;
    right: 0;
    z-index: 1;
  }
  .wrapper-keyboard.numbers .react-simple-keyboard {
    margin-left: -130px;
  }
  .task-wrapper-JegyezdMegAPinKodot .btn-image {
    left: -50px;
  }
  .task-wrapper-JegyezdMegAPinKodot .btn-answer-check {
    right: -50px;
  }
  .task-wrapper-RakdSorrendbeACselekveseket .bg-arrow {
    background-position-y: 435px;
  }
  .task-wrapper-MennyiAprodVan .money-option,
  .task-wrapper-MennyiPapirpenzVanNalad .money-option,
  .task-wrapper-MennyiPenzVanAPenztarcadban .money-option {
    width: 50px !important;
    height: auto !important;
  }
  .task-wrapper-MelyikSzamotHallod .wrapper-keyboard #keyboard-input {
    left: 110px;
  }
}
/* END OF 320px */
.video-preview {
  top: -41px;
  position: relative;
  width: 650px;
  display: block;
  margin: 0 auto;
}
.video-preview.hide {
  display: none;
}
